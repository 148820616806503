.app-sidebar .menu-item {
  margin-left: 0 !important;
  padding: 0;
}
.app-sidebar .menu-item:not(:last-child):not(.show) {
  margin-bottom: 20px;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .app-sidebar-logo {
  border: none;
}
.app-sidebar {
  margin-top: var(--kt-app-header-height) !important;
}
.app-sidebar-header.app-sidebar-logo {
  padding: 20px 20px 20px 30px;
}

.app-sidebar .app-container {
  box-shadow: $pokupka-shadow;
  padding: 0 !important;
}

.app-sidebar .menu-title {
  font-size: 16px;
}

$app-sidebar-base-width: 225px !default;
$app-sidebar-base-width-tablet: 218px !default;
$app-sidebar-base-width-mobile: 240px !default;

:root {
  --kt-app-sidebar-width: #{$app-sidebar-base-width-mobile} !important;
}
[data-kt-app-sidebar-hoverable='true'] .app-sidebar .app-sidebar-wrapper,
.app-sidebar,
.header-logo-minimized {
  width: var(--app-sidebar-base-width-mobile) !important;
}

[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-arrow:after {
  background-color: #787887 !important;
}

@include media-breakpoint-down(md) {
  .app-sidebar.hover-scroll-overlay-y {
    position: absolute;
  }
}

@include media-breakpoint-up(sm) {
  :root {
    --kt-app-sidebar-width: #{$app-sidebar-base-width-tablet} !important;
  }
  [data-kt-app-sidebar-hoverable='true'] .app-sidebar .app-sidebar-wrapper,
  .app-sidebar,
  .header-logo-minimized {
    width: var(--app-sidebar-base-width-tablet) !important;
  }
}
@include media-breakpoint-up(md) {
  :root {
    --kt-app-sidebar-width: #{$app-sidebar-base-width} !important;
  }

  [data-kt-app-sidebar-hoverable='true'] .app-sidebar .app-sidebar-wrapper,
  .app-sidebar,
  .header-logo-minimized {
    width: var(--kt-app-sidebar-width) !important;
  }
}

.app-header .app-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.app-sidebar-toggle-icon {
  width: 25px;
  height: 17px;
}

.hover-scroll-overlay-y {
  background-color: transparent;
}
