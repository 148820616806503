@include media-breakpoint-down(md) {
  .card-body.cardBody {
    padding: 0 2.5rem !important;
  }

  .modal-dialog {
    margin: 0 !important;
  }

  .modal-content {
    border-radius: 0 !important;
  }

  .stepper-nav {
    margin-bottom: 15px;
  }

  body.modal-open iframe#the-chat {
    z-index: 100;
  }

  #kt_scrolltop.scrolltop {
    bottom: 41px;
    left: 55%;
    width: 30px;
    height: 30px;
  }

  iframe#the-chat {
    bottom: -35px !important;
  }
  iframe#the-chat.open {
    bottom: 0 !important;
  }
}

@include media-breakpoint-up(md) {
}
