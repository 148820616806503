#kt_app_content {
  padding: 10px;
}
#kt_app_content_container.app-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: calc(100vh - var(--kt-app-footer-height) - var(--kt-app-header-height) - 50px);
  background-color: white;
}

.card {
  border: none;
  box-shadow: $pokupka-shadow;
}
.card.list-card .card-header,
.card-footer {
  height: 80px;
}
.card-footer {
  position: static;
}

.create-order-height {
  height: calc(100vh - var(--kt-app-footer-height) - var(--kt-app-header-height) - 160px);
}

@include media-breakpoint-down(md) {
  #kt_app_content {
    padding: 0;
  }

  .card .card-body {
    padding: 35px 15px 0 15px;
  }

  .card .card-header {
    padding: 0;
  }
  .card > .card-header:not(.flex-nowrap) {
    padding: 0;
  }
}
@include media-breakpoint-up(md) {
  .card .card-body {
    padding: 0;
  }

  .card-footer.position-absolute {
    bottom: 0;
    right: -1px;
    width: 100%;
  }

  .card-footer button.position-absolute {
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include media-breakpoint-up(lg) {
  #kt_app_content_container.app-container {
    box-shadow: $pokupka-shadow;
  }
}
