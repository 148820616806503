.warehouses-toolbar .nav-item * {
  font-size: 18px;
  color: $grey-text;
}
.warehouses-toolbar .nav-item.active {
  background-color: white;
  box-shadow: $pokupka-shadow;
  border-radius: 30px;
  height: 100%;
  color: 000000;
}

.warehouses-toolbar .icon.country {
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0 4px;
  border: 2px solid $grey-text;
  background: none;
  border-radius: 50%;
}
.warehouses-toolbar .btn:hover > .icon.country,
.warehouses-toolbar .btn-clean > .icon.country,
.warehouses-toolbar .dropdown-item > .icon.country {
  margin-right: 5px;
}
.warehouses .warehouse-line-label,
.warehouses .warehouse-line-value {
  font-size: 16px;
  width: max-content;
}

.warehouse-line {
  min-width: max-content;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.warehouse-line-value-cont {
  padding-left: 40px;
}

.warehouse-line-value-cont,
.warehouse-line-label-cont {
  min-width: max-content;
}

.warehouses-table {
  * td {
    flex-grow: 1 !important;
  }
}

.storages .icon.country svg {
  width: 18px;
  height: 18px;
}
