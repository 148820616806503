.Overview__form_item:not(:last-child) {
  margin-right: 15px;
}

.ProfileShipmentCreateCard:hover > * {
  color: white;
}

.ProfileOverview__passport-image img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.ProfileOverview__edit-image {
  border-radius: 50%;
  z-index: 5;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  left: 95px;
  background: url('../../../../../public/media/company-specific/pokupka/edit.svg'), white;
  background-color: $accent;
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center;
}
.ProfileOverview__delete-image {
  position: absolute;
  top: 10px;
  left: 100px;
  width: 20px !important;
  height: 25px !important;
}
.ProfileOverview__delete-image {
  display: none !important;
}

.ProfileOverview__passport-image {
  border-radius: 50%;
}

.ProfileOverview__passport-image:hover .ProfileOverview__delete-image {
  display: block !important;
}
