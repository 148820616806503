.btn-custom-icon {
  width: 50px;
}
.btn-custom-text {
  width: 120px;
}

.btn-custom-mixed {
  width: 130px;
}

.form-check-input {
  width: 15px;
  height: 15px;
}

// .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
//   box-shadow: unset !important;
// }

@include media-breakpoint-down(md) {
  .card-footer {
    position: relative;
  }
}

@include media-breakpoint-up(md) {
  .profile-address-wrapper {
    position: relative;
  }
}
